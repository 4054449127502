/**
 * @首页home
 * 2022-08-03 kadiam
*/
// import bossApi from '../utils/bossApi'
import bossXypq from '../utils/bossXypq'
import bossCloud from '../utils/bossCloud'

// 商城首页获取
export function common_home(data) {
  return bossXypq({ url: 'hx.baoxian/common/home?discountId=804348273364434944', method: 'GET' })
}

// 商品搜索
// export function mallProduct_searchMallProduct(data) {
//   return bossXypq({ url: 'hx.baoxian/mallProduct/searchMallProduct', method: 'POST', data })
// }
export function mallProduct_searchMallProduct(data) {
  return bossCloud({ url: 'mall/api/mallProduct/search', method: 'POST', data })
}
// 超值通货模块&实惠自用模块&零售创收模块&官方推荐商品列表
export function recommendProductList(data) {
  return bossCloud({ url: 'mall/api/mallProduct/recommendProductList', method: 'POST', data })
}

// 商城首页商品秒杀
// export function home_mallFlash(data) {
//   return bossXypq({ url: 'hx.baoxian/mallFlash/productList', method: 'POST', data })
// }
export function home_mallFlash(data) {
  return bossCloud({ url: 'mall/api/mallFlash/productList', method: 'POST', data })
}

// 商城首页根据品牌查询商品
export function home_byProbrand(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/byProbrand', method: 'POST', data })
}
// 商城首页 营销模块列表
export function mallSaleModule_list(data) {
  return bossXypq({ url: 'hx.baoxian/mallSaleModule/list', method: 'POST', data })
}
// 商城首页 营销模块列表
export function mallSaleModule_imageList(data) {
  return bossXypq({ url: 'hx.baoxian/mallSaleModule/imageList', method: 'POST', data })
}
// 商城首页 新人专享活动列表
export function freshmanActivity(data) {
  return bossXypq({ url: 'hx.baoxian/freshmanActivity/productList', method: 'POST', data })
}
// 商城首页 捡漏专区（多仓库售卖） -- 列表
export function productModule_list(data) {
  return bossXypq({ url: 'hx.baoxian/productModule/list', method: 'POST', data })
}
// 商城首页 捡漏专区（多仓库售卖） -- 商品
// export function productModule_productList(data) {
//   return bossXypq({ url: 'hx.baoxian/productModule/productList', method: 'POST', data })
// }
export function productModule_productList(data) {
  return bossCloud({ url: 'mall/api/mallProductModule/productList', method: 'POST', data })
}
// 商城首页 广告营销 首次登录
export function todayFirstLoginJudge() {
  return bossXypq({ url: 'hx.baoxian/todayFirstLoginJudge', method: 'GET' })
}
