<template>
  <div id="SeckillList">
    <div class="SeckillList_body">
      <!-- 顶部导航 -->
      <van-nav-bar title="" :border="false">
        <template #left>
          <van-icon name="arrow-left" size="18" color="#FFFFFF" @click="backPath" />
        </template>
        <!-- <template #right>
          <van-icon name="service" size="18" color="#FFFFFF" />
        </template> -->
      </van-nav-bar>
      <van-image width="5.28rem" height="2.186rem" fit="contain"
                 src="https://oss.xiaoyi120.com/shop2.0/seckill/top_msg.png"
                 style="margin-bottom:-15px"
      />
      <!-- 倒计时 -->
      <van-count-down v-if="list[0]" :time="list[0].time" format="DD/ HH:mm:ss">
        <template #default="timeData">
          <div class="timeDataStyle">
            <div v-if="tabIndex === 1 " class="colon">距结束还剩</div>
            <div v-if="tabIndex === 2 " class="colon">距开始还剩</div>
            <div v-if="timeData.days" class="block">{{ timeData.days }}</div>
            <div v-if="timeData.days" class="colon">天</div>
            <div class="block">{{ timeData.hours }}</div>
            <div class="colon">时</div>
            <div class="block">{{ timeData.minutes }}</div>
            <div class="colon">分</div>
            <div class="block">{{ timeData.seconds }}</div>
            <div class="colon">秒</div>
          </div>
        </template>
      </van-count-down>
    </div>
    <!-- tab切换 -->
    <div class="SeckillList_tab flex-c-c-c">
      <div :class="tabIndex === 1 ? 'tab_change tab_left':'tab_change tab_right'">
        <div :class="tabIndex === 1 ? 'left_msg tab_actived':'left_msg'" @click="tabChange(1)">正在秒杀</div>
        <div :class="tabIndex === 2 ? 'right_msg tab_actived':'right_msg'" @click="tabChange(2)">即将开始</div>
      </div>
      <div class="tab_list">
        <van-list :loading="loading" :finished="finished" finished-text="我也是有底线的..." @load="onLoad">
          <div v-for="(pro_item,pro_index) in list" :key="pro_index" class="pro_card">
            <div class="card_left">
              <van-image width="2.56rem" height="1.92rem" fit="contain" :src="pro_item.flashPic" />
            </div>
            <div class="card_right">
              <div class="pro_name overflow-ellipsis">{{ pro_item.productName }}</div>
              <div class="pro_brand_model overflow-ellipsis">
                <span class="pro_brand">{{ pro_item.brand }}</span> |
                <span class="pro_model">{{ pro_item.model }}</span>
              </div>
              <div class="pro_price_btn">
                <div class="pro_price">
                  <div class="pro_price_seckill">秒杀价 ￥<span
                    style="font-size:0.53rem"
                  >{{ $utils.moneyDote(pro_item.flashPrice)[0] }}</span>.{{ $utils.moneyDote(pro_item.flashPrice)[1] }}
                  </div>
                  <div class="pro_price_shop">商城价￥{{ pro_item.price }}</div>
                </div>
                <div v-if="tabIndex === 1" class="pro_btn"
                     @click="$router.push('/product/detail?productId=' + pro_item.productId)"
                >
                  <div>去抢购</div>
                </div>
                <div v-else class="pro_btn">
                  <div>赶来中</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty v-if="list.length === 0" class="custom-image"
                   image="https://oss.xiaoyi120.com/shop2.0/home/serpro_empty.png"
                   description="秒杀好物正在准备中..."
        />
      </div>
    </div>

  </div>
</template>

<script>
import './index.scss'
import { home_mallFlash } from '@/api/home'
// 组件加载
export default {
  name: 'SeckillList',
  data() {
    return {
      // 倒计时
      time: 30 * 60 * 60 * 1000, // 30 代表分钟
      // tab
      tabIndex: 1,
      // 滚动列表
      loading: false,
      finished: false,
      list: [],
      serchFrom: { limit: 10, page: 0, isNow: 1 }
    }
  },
  methods: {
    backPath() { window.history.back() },
    // 切换tab
    tabChange(index) {
      this.tabIndex = index
      this.serchFrom.isNow = index
      this.serchFrom.page = 1
      this.finished = false
      this.list = []
      this.seckillList()
    },
    onLoad() {
      setTimeout(() => {
        this.serchFrom.page += 1
        this.seckillList()
        // 加载状态结束
        this.loading = false
        // 数据全部加载完成
        if (this.list.length >= 40) { this.finished = true }
      }, 10)
    },
    // 秒杀列表
    seckillList() {
      home_mallFlash(this.serchFrom).then(res => {
        console.log('res.data', res.data)
        if (this.tabIndex === 1) {
          const startDate1 = new Date().getTime()
          const endDate1 = new Date(res.data.list[0].endDate.replace(/-/g, '/')).getTime()
          this.$set(res.data.list[0], 'time', (endDate1 - startDate1))
        }
        if (this.tabIndex === 2) {
          const startDate1 = new Date().getTime()
          const endDate1 = new Date(res.data.list[0].startDate).getTime()
          this.$set(res.data.list[0], 'time', (endDate1 - startDate1))
        }
        console.log('res.data', res.data.list[0].time)
        this.list = this.list.concat(res.data.list)
      })
    }
  }
}
</script>
